<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 5 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 5: Interior Millwork Main Floor
                    </div >
                    <div class="display- font-weight-light"> 
                    Doors, Kitchen Cabinetry, Vanities& Linen Closet Face Frame Selections
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>                           
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                            <v-text-field
                                label="Homeowner 2 - Last Name"
                                color="black"
                                v-model="this.selected_user.SecondaryLastName"
                            />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col cols="12">
                              <v-header><strong>Main Floor</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_1.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']"  v-model="section.column_1"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_2.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3" ><v-select :items="['Yes','No','Undecided']"  v-model="section.column_2"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_3.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3" ><v-select :items="['Yes','No','Undecided']" v-model="section.column_3"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_4.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_4"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_5.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_5"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_6.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_6"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_7.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_7"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_8.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_8"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_9.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_9"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_10.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_10"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_11.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_11"></v-select></v-col>
                           <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_12.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']"  v-model="section.column_12"></v-select></v-col>
                            <v-col cols="12" md="4" ><v-subheader>{{checkbox_text_13.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="8"><v-select :items="['Yes','No','Undecided']" v-model="section.column_13"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_14.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_14"></v-select></v-col>
                          <v-col cols="12" md="1" ><v-subheader> LOCATIONS:</v-subheader></v-col>
                          <v-col cols="12" md="5"><v-text-field v-model="section.column_15"></v-text-field></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_15.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_16"></v-select></v-col>
                          <v-col cols="12" md="1" ><v-subheader> LOCATIONS:</v-subheader></v-col>
                          <v-col cols="12" md="5"><v-text-field v-model="section.column_17"></v-text-field></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_16.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_18"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_17.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_19"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_18.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_20"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_19.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_21"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_20.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']"  v-model="section.column_22"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_21.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']"  v-model="section.column_23"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_22.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_24"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_23.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']"  v-model="section.column_25"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_text_24.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']"  v-model="section.column_26"></v-select></v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                        </v-row>
                      </v-container>
                  </v-form>
                   </base-material-card>
                            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_27"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_28"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_29"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_30"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>
                            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 5: Interior Millwork Upper Level
                    </div >
                    <div class="display- font-weight-light">
                    Doors, Kitchen Cabinetry, Vanities& Linen Closet Face Frame Selections
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Buyer 1 - First Name"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Buyer 1 - Last Name"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Buyer 2 - First Name"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                            <v-text-field
                                label="Buyer 2 - Last Name"
                                v-model="this.selected_user.SecondaryLastName"
                            />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>

                            <v-col cols="12">
                              <v-header><strong>Upper Level</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_1.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_31"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_2.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_32"></v-select></v-col>
                           <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_3.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_33"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_4.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_34"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_5.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_35"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_6.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_36"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_7.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_37"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_8.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_38"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_9.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="9"><v-select :items="['Yes','No','Undecided']" v-model="section.column_39"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_10.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_40"></v-select></v-col>
                          <v-col cols="12" md="1" ><v-subheader> LOCATIONS:</v-subheader></v-col>
                          <v-col cols="12" md="5"><v-text-field v-model="section.column_41"></v-text-field></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_11.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_42"></v-select></v-col>
                          <v-col cols="12" md="1" ><v-subheader> LOCATIONS:</v-subheader></v-col>
                          <v-col cols="12" md="5"><v-text-field v-model="section.column_43"></v-text-field></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_12.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_44"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_13.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_45"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_14.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_46"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_15.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_47"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_16.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_48"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_17.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_49"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_18.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_50"></v-select></v-col>
                          <v-col col="12" md="12">
                                <v-header>Notes:<v-text-field v-model="section.column_51"/></v-header>
                            </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_52"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_53"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_54"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_55"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 5: Interior Millwork Basement
                    </div >
                    <div class="display- font-weight-light">
                    Doors, Kitchen Cabinetry, Vanities& Linen Closet Face Frame Selections
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Buyer 1 - First Name"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Buyer 1 - Last Name"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Buyer 2 - First Name"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                            <v-text-field
                                label="Buyer 2 - Last Name"
                                v-model="this.selected_user.SecondaryLastName"
                            />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-header><strong>Basement</strong></v-header>
                            </v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_1.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_56"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_2.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_57"></v-select></v-col>
                           <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_3.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_58"></v-select></v-col>
                          <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_4.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_59"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_5.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_60"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_6.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_61"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_7.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_62"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_8.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_63"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_9.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="9"><v-select :items="['Yes','No','Undecided']" v-model="section.column_64"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_10.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_65"></v-select></v-col>
                          <v-col cols="12" md="1" ><v-subheader> LOCATIONS:</v-subheader></v-col>
                          <v-col cols="12" md="5"><v-text-field v-model="section.column_66"></v-text-field></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_11.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_67"></v-select></v-col>
                          <v-col cols="12" md="1" ><v-subheader> LOCATIONS:</v-subheader></v-col>
                          <v-col cols="12" md="5"><v-text-field v-model="section.column_68"></v-text-field></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_12.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_69"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_13.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_70"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_14.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_71"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_15.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_72"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_16.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_73"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_17.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_74"></v-select></v-col>
                            <v-col cols="12" md="3" ><v-subheader>{{checkbox_base_text_18.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :items="['Yes','No','Undecided']" v-model="section.column_75"></v-select></v-col>
                          <v-col col="12" md="12">
                                <v-header>Notes:<v-text-field v-model="section.column_76"/></v-header>
                            </v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <div v-if="permissions.scope === 'create:users,fullaccess:staff'">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                      </div>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                           </v-dialog>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                            <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_77"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_78"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_79"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_80"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Save</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
    name:'Section5',
    data () {
        return {
            checkbox_text_1:'1) ALL INTERIOR MILLWORK STAINED',
            checkbox_text_2:'2) ALL INTERIOR MILLWORK PAINTED WHITE ENAMEL ON JOB SITE',
            checkbox_text_3:'3) ALL INTERIOR MILLWORK ORDERED PRE-FINISHED WHITE & PUTTY NAIL HOLES ONLY',
            checkbox_text_4:'4) ALL KITCHEN CABINETS STAINED',
            checkbox_text_5:'5) ALL KITCHEN CABINETS PAINTED WHITE ENAMEL ON JOB SITE ',
            checkbox_text_6:'5-B) CENTER ISLAND STAINED (STANDARD)',
            checkbox_text_7:'5-C)	CENTER ISLAND PAINTED',
            checkbox_text_8:'6) ALL BATHROOM VANITIES STAINED (STANDARD)',
            checkbox_text_9:'7) ALL BATHROOM VANITIES PAINTED WHITE ENAMEL ON JOB SITE',
            checkbox_text_10:'8) ALL LINEN CLOSETS FACE FRAMED STAINED (STANDARD)',
            checkbox_text_11:'9) ALL LINEN CLOSETS EXTERIOR PAINTED WHITE ENAMEL ON JOB SITE',
            checkbox_text_12:'10) ALL INTERIOR DOORS STANDARD 3 PANEL PRE FINISHED WHITE ENAMEL (STANDARD)',
            checkbox_text_13:'11) ALL INTERIOR DOORS STAINED ON JOB SITE',
            checkbox_text_14:'11-A) INTERIOR DOORS OPTIONAL STAINED',
            checkbox_text_15:'11-B) INTERIOR DOORS OPTIONAL PAINTED',
            checkbox_text_16:'12)	FIREPLACE MANTEL - PAINTED',
            checkbox_text_17:'12-B) STAINED (STANDARD)',
            checkbox_text_18:'13)	SKIRTBOARDS - PAINTED ',
            checkbox_text_19:'13-B) STAINED (STANDARD)',
            checkbox_text_20:'14) HANDRAILS - PAINTED',
            checkbox_text_21:'14-B) STAINED (STANDARD)',
            checkbox_text_22:'15)	BALUSTERS - PAINTED WOOD OPTION',
            checkbox_text_23:'15-B) STAINED WOOD OPTION',
            checkbox_text_24:'15-C) METAL (SATIN BLACK) 1STANDARD)',
            checkbox_base_text_1:'1) ALL INTERIOR MILLWORK STAINED (STANDARD)',
            checkbox_base_text_2:'2) ALL INTERIOR MILLWORK PAINTED WHITE ENAMEL ON JOB SITE',
            checkbox_base_text_3:'3) ALL INTERIOR MILLWORK ORDERED PRE-FINISHED WHITE & PUTTY NAIL HOLES ONLY',
            checkbox_base_text_4:'4) ALL BATHROOM VANITIES STAINED (STANDARD)',
            checkbox_base_text_5:'5) ALL BATHROOM VANITIES PAINTED WHITE ENAMEL ON JOB SITE',
            checkbox_base_text_6:'6) ALL LINEN CLOSETS FACE FRAMED STAINED (STANDARD)',
            checkbox_base_text_7:'7) ALL LINEN CLOSETS EXTERIOR PAINTED WHITE ENAMEL ON JOB SITE',
            checkbox_base_text_8:'8) ALL INTERIOR DOORS 3 PANEL PRE FINISHED WHITE ENAMEL (STANDARD)',
            checkbox_base_text_9:'9) ALL INTERIOR DOORS STAINED ON JOB SITE',
            checkbox_base_text_10:'9-A) ALL INTERIOR DOORS STAINED',
            checkbox_base_text_11:'9-B) ALL INTERIOR DOORS PAINTED',
            checkbox_base_text_12:'10) SKIRTBOARDS - PAINTED',
            checkbox_base_text_13:'10-B) STAINED (STANDARD)',
            checkbox_base_text_14:'11) HANDRAILS - PAINTED',
            checkbox_base_text_15:'11-B) STAINED (STANDARD)',
            checkbox_base_text_16:'12) BALUSTERS - PAINTED WOOD OPTION',
            checkbox_base_text_17:'12-B) STAINED WOOD OPTION',
            checkbox_base_text_18:'12-C) METAL (SATIN BLACK) (STANDARD)',
            home_id: localStorage.getItem("homeid"),
            alert:false,
            dialog: null,
            valid: null,
            section_id: "5",
            add_vendor_email:'',
            vendor_email: [],
            selected_vendor: {
                ItemId:0,
                Vendor: '',
                Name:'',
                Email: '',
                ModifiedDate:''
            },
            default_vendor: {
                ItemId:0,
                Vendor: '',
                Name:'',
                Email: '',
                ModifiedDate:''
            },
            staff_email: 'steve@skelleymarketing.com',
            section: {
              column_1:'',
              column_2:'',
              column_3:'',
              column_4:'',
              column_5:'',
              column_6:'',
              column_7:'',
              column_8:'',
              column_9:'',
              column_10:'',
              column_11:'',
              column_12:'',
              column_13:'',
              column_14:'',
              column_15:'',
              column_16:'',
              column_17:'',
              column_18:'',
              column_19:'',
              column_20:'',
              column_21:'',
              column_22:'',
              column_23:'',
              column_24:'',
              column_25:'',
              column_26:'',
              column_27:'',
              column_28:'',
              column_29:'',
              column_30:'',
              column_31:'',
              column_32:'',
              column_33:'',
              column_34:'',
              column_35:'',
              column_36:'',
              column_37:'',
              column_38:'',
              column_39:'',
              column_40:'',
              column_41:'',
              column_42:'',
              column_43:'',
              column_44:'',
              column_45:'',
              column_46:'',
              column_47:'',
              column_48:'',
              column_49:'',
              column_50:'',
              column_51:'',
              column_52:'',
              column_53:'',
              column_54:'',
              column_55:'',
              column_56:'',
              column_57:'',
              column_58:'',
              column_59:'',
              column_60:'',
              column_61:'',
              column_62:'',
              column_63:'',
              column_64:'',
              column_65:'',
              column_66:'',
              column_67:'',
              column_68:'',
              column_69:'',
              column_70:'',
              column_71:'',
              column_72:'',
              column_73:'',
              column_74:'',
              column_75:'',
              column_76:'',
              column_77:'',
              column_78:'',
              column_79:'',
              column_80:''
          },
          selected_user: {
              PrimaryEmailAddress:'',
              SecondaryEmailAddress:'',
              PrimaryFirstName: '',
              PrimaryLastName: '',
              SecondaryFirstName: '',
              SecondaryLastName: '',
              Address:'',
              City:'',
              State: '',
              Zip: '',
              BuildStart: '',
              BuildEnd:'',
              CellNumber:'',
              HomeNumber: '',
              WorkPhone:'',
              Role:'',
              Agent:''
          },
        }
    },
   created () {
     this.getSection()
     this.getVendorEmail()
     this.getUserInfo()
    },
    methods:{
      getSection(){
          axios
          .get(process.env.VUE_APP_GET_SECTION_TWO_URL,
          {
          params: {
              home_id: this.home_id,
              section_id: this.section_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.section = JSON.parse(result['JsonDetails'])
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getUserInfo(){
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {
                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
      },
      getVendorEmail(){
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
          })
          .catch(error => {
          console.log(error)
          })
      },
      sectionComplete(){
        var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
        }
const theNewContent = {
  homeOwner: {
    ...this.selected_user
    },
    sectionData: [
      {
        Title:`Main-LVL-${this.checkbox_text_1}`,
        Value:this.section.column_1
        },
        {
        Title:`Main-LVL-${this.checkbox_text_2}`,
        Value:this.section.column_2
      },
      {
        Title:`Main-LVL-${this.checkbox_text_3}`,
        Value:this.section.column_3
      },
      {
        Title:`Main-LVL-${this.checkbox_text_4}`,
        Value:this.section.column_4
      },
      {
        Title:`Main-LVL-${this.checkbox_text_5}`,
        Value:this.section.column_5
      },
      {
        Title:`Main-LVL-${this.checkbox_text_6}`,
        Value:this.section.column_6
      },
      {
        Title:`Main-LVL-${this.checkbox_text_7}`,
        Value:this.section.column_7
      },
      {
        Title:`Main-LVL-${this.checkbox_text_8}`,
        Value:this.section.column_8
      },
      {
        Title:`Main-LVL-${this.checkbox_text_9}`,
        Value:this.section.column_9
      },
      {
        Title:`Main-LVL-${this.checkbox_text_10}`,
        Value:this.section.column_10
      },
      {
        Title:`Main-LVL-${this.checkbox_text_11}`,
        Value:this.section.column_11
      },
      {
        Title:`Main-LVL-${this.checkbox_text_12}`,
        Value:this.section.column_12
      },
      {
        Title:`Main-LVL-${this.checkbox_text_13}`,
        Value:this.section.column_13
      },
      {
        Title:`Main-LVL-${this.checkbox_text_14}`,
        Value:this.section.column_14
      },
      {
        Title:`Main-LVL-${this.checkbox_text_15}`,
        Value:this.section.column_15
      },
      {
        Title:`Main-LVL-${this.checkbox_text_16}`,
        Value:this.section.column_16
      },
      {
        Title:`Main-LVL-${this.checkbox_text_17}`,
        Value:this.section.column_17
      },
      {
        Title:`Main-LVL-${this.checkbox_text_18}`,
        Value:this.section.column_18
      },
      {
        Title:`Main-LVL-${this.checkbox_text_19}`,
        Value:this.section.column_19
      },
      {
        Title:`Main-LVL-${this.checkbox_text_20}`,
        Value:this.section.column_20
      },
      {
        Title:`Main-LVL-${this.checkbox_text_21}`,
        Value:this.section.column_21
      },
      {
        Title:`Main-LVL-${this.checkbox_text_22}`,
        Value:this.section.column_22
      },
      {
        Title:`Main-LVL-${this.checkbox_text_23}`,
        Value:this.section.column_23
      },
      {
        Title:`Main-LVL-${this.checkbox_text_24}`,
        Value:this.section.column_24
      },
      {
        Title:`Main-LVL-${this.checkbox_text_25}`,
        Value:this.section.column_25
      },
      {
        Title:`Main-LVL-${this.checkbox_text_26}`,
        Value:this.section.column_26
      },
      {
        Title:'Homeowner #1',
        Value: this.section.column_27
      },
      {
        Title:'Date',
        Value: this.section.column_28
      },
      {
        Title:'Homeowner #2',
        Value: this.section.column_29
      },
      {
        Title:'Date',
        Value: this.section.column_30
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_1}`,
        Value:this.section.column_31
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_2}`,
        Value:this.section.column_32
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_3}`,
        Value:this.section.column_33
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_4}`,
        Value:this.section.column_34
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_5}`,
        Value:this.section.column_35
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_6}`,
        Value:this.section.column_36
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_7}`,
        Value:this.section.column_37
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_8}`,
        Value:this.section.column_38
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_9}`,
        Value:this.section.column_39
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_10}`,
        Value:this.section.column_40
      },
      {
        Title:'LOCATIONS',
        Value: this.section.column_41
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_11}`,
        Value:this.section.column_42
      },
      {
        Title:'LOCATIONS',
        Value: this.section.column_43
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_12}`,
        Value:this.section.column_44
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_13}`,
        Value:this.section.column_45
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_14}`,
        Value:this.section.column_46
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_15}`,
        Value:this.section.column_47
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_16}`,
        Value:this.section.column_48
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_17}`,
        Value:this.section.column_49
      },
      {
        Title:`UPPER-LVL-${this.checkbox_text_18}`,
        Value:this.section.column_50
      },
      {
        Title:`Notes`,
        Value:this.section.column_51
      },
      {
        Title:'Homeowner #1',
        Value: this.section.column_52
      },
      {
        Title:'Date',
        Value: this.section.column_53
      },
      {
        Title:'Homeowner #2',
        Value: this.section.column_54
      },
      {
        Title:'Date',
        Value: this.section.column_55
      },
      {
        Title:`BASEMENT-${this.checkbox_text_1}`,
        Value:this.section.column_56
      },
      {
        Title:`BASEMENT-${this.checkbox_text_2}`,
        Value:this.section.column_57
      },
      {
        Title:`BASEMENT-${this.checkbox_text_3}`,
        Value:this.section.column_58
      },
      {
        Title:`BASEMENT-${this.checkbox_text_4}`,
        Value:this.section.column_59
      },
      {
        Title:`BASEMENT-${this.checkbox_text_5}`,
        Value:this.section.column_60
      },
      {
        Title:`BASEMENT-${this.checkbox_text_6}`,
        Value:this.section.column_61
      },
      {
        Title:`BASEMENT-${this.checkbox_text_7}`,
        Value:this.section.column_62
      },
      {
        Title:`BASEMENT-${this.checkbox_text_8}`,
        Value:this.section.column_63
      },
      {
        Title:`BASEMENT-${this.checkbox_text_9}`,
        Value:this.section.column_64
      },
      {
        Title:`BASEMENT-${this.checkbox_text_10}`,
        Value:this.section.column_65
      },
      {
        Title:`LOCATIONS`,
        Value:this.section.column_66
      },
      {
        Title:`BASEMENT-${this.checkbox_text_11}`,
        Value:this.section.column_67
      },
      {
        Title:`LOCATIONS`,
        Value:this.section.column_68
      },
      {
        Title:`BASEMENT-${this.checkbox_text_12}`,
        Value:this.section.column_69
      },
      {
        Title:`BASEMENT-${this.checkbox_text_13}`,
        Value:this.section.column_70
      },
      {
        Title:`BASEMENT-${this.checkbox_text_14}`,
        Value:this.section.column_71
      },
      {
        Title:`BASEMENT-${this.checkbox_text_15}`,
        Value:this.section.column_72
      },
      {
        Title:`BASEMENT-${this.checkbox_text_16}`,
        Value:this.section.column_73
      },
      {
        Title:`BASEMENT-${this.checkbox_text_17}`,
        Value:this.section.column_74
      },
      {
        Title:`BASEMENT-${this.checkbox_text_18}`,
        Value:this.section.column_75
      },
      {
        Title:'Notes',
        Value: this.section.column_76
      },
      {
        Title:'Homeowner #1',
        Value: this.section.column_77
      },
      {
        Title:'Date',
        Value: this.section.column_78
      },
      {
        Title:'Homeowner #2',
        Value: this.section.column_79
      },
      {
        Title:'Date',
        Value: this.section.column_80},
],
vendorData: {
    ...this.selected_vendor
}
}
        axios
        .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
        .then(() => {
            this.alert = true
            
            if (this.add_vendor_email[0] === 'Y')
            this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
            })
            
        .catch(error => {
            console.log(error)
        })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
        },
      close () {
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
      sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
        permissions(){
        return {scope: localStorage.getItem("permissions")}
        }
    }
}
</script>